import React from 'react';

import { Avatar, Col, Row } from 'antd';

import content3Image from '../../assets/images/pages/landing/content-3.png';
import content31Image from '../../assets/images/pages/landing/content-3.1.png';

import saftyImage from '../../assets/images/pages/landing/safty.png';

import { AiFillSafetyCertificate } from 'react-icons/ai';
import { BsSafe2Fill } from 'react-icons/bs';
import { GiTechnoHeart } from 'react-icons/gi';

export default function LandingContent3() {
  return (
    <section className='hp-landing-content-3 hp-overflow-hidden hp-py-sm-64 hp-py-120' id='technologie'>
      <div className='hp-landing-container hp-mr-md-0 hp-mr-120'>
        <Row align='middle' justify='space-between'>
          <Col xl={10} lg={14} md={16} span={24}>
            <h2 className='h1 hp-mb-32'>Kombination aus Sicherheit, Zuverlässigkeit und Wissen</h2>

            <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
              Der Schutz Ihrer Anlagen ist unsere höchste Priorität
            </p>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <BsSafe2Fill style={{ color: '#0072ed', width: '100%', height: '100%' }} />
                  </Col>
                  <Col span={22}>
                    <h4 className='hp-font-weight-500 hp-mb-0'>Sicherheit</h4>
                  </Col>
                </Row>
                <Row className='hp-pt-24'>
                  <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
                    Unser umfangreiches Sicherheitskonzept bietet mehrere Schutzebenen und sorgt so dafür, dass nur Sie
                    Zugang zu Ihrem Vermögen erhalten. Zusätzlich verwenden wir zwei getrennte Sicherheitsschlüssel
                    wodurch es uns ermöglicht in Ihrem Namen zu Staken ohne ihre Assets zu besitzen.
                  </p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <AiFillSafetyCertificate style={{ color: '#0059b9', width: '100%', height: '100%' }} />
                  </Col>
                  <Col span={22}>
                    <h4 className='hp-font-weight-500 hp-mb-0'>Zuverlässigkeit</h4>
                  </Col>
                </Row>
                <Row className='hp-pt-24'>
                  <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
                    Die diversifizierte Infrastruktur, multiredundante Netzanbindungen sowie Server in verschiedenen
                    Regionen Deutschlands stellen eine optimale, fehlerarme System-Architektur dar. Dies bietet ein
                    hohes Maß an Zuverlässigkeit und Datenschutz.
                  </p>
                </Row>
              </Col>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <GiTechnoHeart style={{ color: '#4895e8', width: '100%', height: '100%' }} />
                  </Col>
                  <Col span={22}>
                    <h4 className='hp-font-weight-500 hp-mb-0'>Wissen</h4>
                  </Col>
                </Row>
                <Row className='hp-pt-24'>
                  <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
                    Durch Kombination aus Wissen in den Bereichen Blockchain, Software und Smart Contract Entwicklung
                    sowie Cloud und Server Infrastruktur ermöglicht uns ein reibungslosen Betrieb der komplexen Soft-
                    und Hardware Struktur im großem Umfang.
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xl={14} lg={10} span={24} className='hp-mt-md-64'>
            <div className='hp-landing-content-3-img'>
              <img src={content3Image} alt='Perfect Solution For Small Business' />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
