const themeConfig = {
  version: '1.0',
  theme: 'dark', // light - dark
  contentWidth: 'full', // full - boxed
  sidebarCollapsed: false,
  sidebarCollapseButton: true,
  layout: 'VerticalLayout', // VerticalLayout - HorizontalLayout
  navigationFull: false,
  navigationBg: false,
  direction: 'ltr', // ltr - rtl
  language: 'de', // tr - en - es - fr
};

export default themeConfig;
