import { Link } from 'react-router-dom';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import React from 'react';
// import logoSmall from '../../../../assets/images/logo/logo-small.svg';
// import logoSmallDark from '../../../../assets/images/logo/logo-small-dark.svg';
// import logo from '../../../../assets/images/logo/logo.svg';
// import logoDark from '../../../../assets/images/logo/logo-dark.svg';

import logoPng from '../../../../assets/images/logo/logo.png';

export default function MenuLogo(props) {
  const customise = useSelector((state) => state.customise);

  return (
    <div className='hp-header-logo hp-d-flex hp-align-items-center'>
      <Link to='/' onClick={props.onClose} className='hp-position-relative hp-d-flex'>
        <img className='hp-logo' src={logoPng} alt='logo' />
        <Col className='hp-landing-content-4-item-subtitle' style={{ paddingLeft: 20 }}>
          <span className='hp-d-block h4 hp-font-weight-400'>WINTHEREUM</span>
          <span className='hp-d-block hp-caption hp-font-weight-200 hp-text-color-black-60 hp-text-color-dark-40'>
            Industrial Staking Provider
          </span>
        </Col>
        {/* <span className='h3 d-font-weight-800 da-text-color-primary-5 da-mb-6'>PRISMA</span> */}
      </Link>
    </div>
  );
}
