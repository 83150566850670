import React from 'react';

import LandingHeader from './header';
import LandingBanner from './banner';
import LandingContent1 from './content-1';
import LandingContent2 from './content-2';
import LandingContent3 from './content-3';
import LandingContent4 from './content-4';
import LandingFeatures from './features';
import LandingFooter from './footer';

export default function Landing() {
  return (
    <div className='hp-landing hp-bg-black-0 hp-bg-dark-90'>
      <LandingHeader />
      <LandingBanner />
      {/* <LandingContent1 /> */}
      <LandingContent2 />
      <LandingContent3 />
      <LandingContent4 />
      <LandingFeatures />
      <LandingFooter />
    </div>
  );
}
