import ScrollTop from './components/scroll-to-top';
import React from 'react';

export default function FullLayout({ children }) {
  return (
    <>
      {children} <ScrollTop />
    </>
  );
}
