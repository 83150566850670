import React from 'react';

import { Col, Row } from 'antd';

import content4 from '../../assets/images/pages/landing/content-4.png';

import { AiFillSafetyCertificate, AiFillDatabase } from 'react-icons/ai';
import { BsSafe2Fill } from 'react-icons/bs';
import { MdOutlineMilitaryTech } from 'react-icons/md';
import { SiSimpleanalytics } from 'react-icons/si';
import { HiCubeTransparent } from 'react-icons/hi';

export default function LandingContent4() {
  const contentValues = [
    {
      image: <BsSafe2Fill style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Sicherheit',
      subtitle: 'Sicherheit',
      comment:
        'Wir verwenden zwei getrennte Sicherheitsschlüssel: einen für die Validierung und einen für die Übertragung von Geldern. Wir stellt den ersten zur Verfügung und Sie generieren und behalten den zweiten, wodurch sichergestellt wird, dass niemand anderes als Sie Zugriff auf Ihre Gelder hat.',
    },
    {
      image: <AiFillSafetyCertificate style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Vertrauen',
      subtitle: 'Vertrauen',
      comment:
        'Durch unserer diversifizierte Infrastruktur, multiredundante Netzanbindungen sowie Server in verschiedenen Regionen Deutschlands können wir eine hohe Betriebszeit gewährleisten und somit die höchsten Gewinne zu erzielen.',
    },
    {
      image: <MdOutlineMilitaryTech style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Zuverlässigkeit',
      subtitle: 'Zuverlässigkeit',
      comment:
        'Durch unserer diversifizierte Infrastruktur, multiredundante Netzanbindungen sowie Server in verschiedenen Regionen Deutschlands   können wir eine hohe Betriebszeit gewährleisten und somit die höchsten Gewinne zu erzielen.',
    },
    {
      image: <SiSimpleanalytics style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Einfachkeit',
      subtitle: 'Einfachkeit',
      comment:
        'Sie müssen nur den privaten Schlüssel erstellen und aufbewahren, der Ihre Gelder kontrolliert. Danach können Sie mit nur wenigen Klicks auf dem Kundenportal mit dem Verdienen von Prämien beginnen.',
    },
    {
      image: <HiCubeTransparent style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Transparenz',
      subtitle: 'Transparenz',
      comment:
        'Ein Kundenportal bietet aktuelle Informationen über den Zustand der Nodes,  Ihrer Fonds sowie über das gesamten Ethereum Netzwerks.',
    },
    {
      image: <AiFillDatabase style={{ color: '#0072ed', scale: '3' }} />,
      title: 'Datenschutz',
      subtitle: 'Datenschutz',
      comment:
        'Durch konsistente Datenspeicherung auf Deutschen Servern bieten wir einen höchsten maß an Datenschutz dar.',
    },
  ];

  const contentListMap = contentValues.map((item, index) => (
    <Col key={index} md={8} span={24}>
      <div className='hp-landing-content-4-item hp-position-relative hp-border-radius hp-bg-black-0 hp-bg-dark-90 hp-p-sm-16 hp-p-24'>
        <Row align='middle'>
          <Col>{item.image}</Col>

          <Col className='hp-landing-content-4-item-subtitle' style={{ paddingLeft: 20 }}>
            <span className='hp-d-block h5 hp-font-weight-400'>{item.title}</span>
            {/* <span className='hp-d-block hp-caption hp-font-weight-400 hp-text-color-black-60 hp-text-color-dark-40'>
              {item.subtitle}
            </span> */}
          </Col>
        </Row>

        <p className='h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 hp-mt-16 hp-mb-0'>
          {item.comment}
        </p>
      </div>
    </Col>
  ));

  return (
    <section
      className='hp-landing-content-4 hp-bg-black-10 hp-bg-dark-100  hp-py-sm-64 hp-pt-96 hp-pb-120 hp-position-relative hp-overflow-hidden'
      id='features'
    >
      <div className='hp-landing-container'>
        <Row justify='center'>
          <Col xl={14} lg={15} md={18} span={24} className='hp-text-center'>
            <h2 className='h1 hp-mb-4'>Wir machen den Unterschied</h2>

            <p className='h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 hp-mt-sm-0 hp-mt-32 hp-mb-0'>
              Staking mit Winthereum ist der sicherste Weg, um Ethereum 2 Prämien zu erhalten
            </p>
          </Col>

          <Col span={24} className='hp-mt-64'>
            <Row gutter={[32, 32]}>{contentListMap}</Row>
          </Col>
        </Row>
      </div>
    </section>
  );
}
