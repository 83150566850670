import { useEffect } from 'react';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import { theme } from './redux/customise/customiseActions';
import FullLayout from './layout/FullLayout';
import AppLocale from './languages';

import HomePage from './view/landing';

export default function App() {
  const dispatch = useDispatch();
  const customise = useSelector((state) => state.customise);

  const currentAppLocale = AppLocale[customise.language];

  const Layouts = { FullLayout };

  // Dark Mode
  let themeLocal;

  useEffect(() => {
    if (localStorage) {
      themeLocal = localStorage.getItem('theme');
    }
    if (themeLocal === 'light' || themeLocal === 'dark') {
      document.querySelector('body').classList.add(themeLocal);
      dispatch(theme(themeLocal));
    } else {
      document.querySelector('body').classList.add(customise.theme);
      dispatch(theme(customise.theme));
    }
  }, []);

  useEffect(() => {
    document.querySelector('html').setAttribute('lang', customise.language);
  }, [customise]);

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={customise.direction}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Layouts.FullLayout>
          <HomePage />
        </Layouts.FullLayout>
      </IntlProvider>
    </ConfigProvider>
  );
}
