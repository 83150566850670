import React from 'react';

import { Avatar, Col, Row, Image } from 'antd';
import { Document, TicketStar } from 'react-iconly';

import content2Image from '../../assets/images/pages/landing/content-2.png';
import content21Image from '../../assets/images/pages/landing/content-2.1.png';

export default function LandingContent2() {
  return (
    <section
      className='hp-landing-content-2 hp-bg-black-10 hp-bg-dark-100  hp-pt-sm-80 hp-pb-sm-64 hp-pb-80 hp-overflow-hidden'
      id='staking-service'
    >
      <Row align='middle' justify='center'>
        <Col xl={16} lg={14} md={16} span={24} className='hp-px-16 hp-text-center hp-mb-sm-64 hp-mb-24'>
          <h2 className='h1 hp-mb-32'>Managed Staking Service</h2>

          <p className='h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30'>
            Erhalten Sie kontinulierlichen Cashflow auf Ihrer Kryptowährung
          </p>
        </Col>
      </Row>

      <div className='hp-landing-container hp-mr-md-0 hp-mr-120 '>
        <Row align='middle' justify='space-between'>
          <Col xl={14} lg={10} span={24} className='hp-mt-md-64'>
            <div className='hp-landing-content-3-img'>
              <img src={content21Image} alt='Perfect Solution For Small Business' />
            </div>
          </Col>
          <Col xl={10} lg={14} md={16} span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={22}>
                    <h4 className='hp-font-weight-500 hp-mb-0'> Ethereum Staking Service</h4>
                  </Col>
                </Row>
                <Row className='hp-pt-24'>
                  <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
                    Winthereum bietet einen institutionellen Staking-Service für Ether-Inhaber an, der es Ihnen
                    ermöglicht am Staking von Ethereum teilzunehmen, Belohnungen zu verdienen und gleichzeitig das
                    Ethereum Netzwerk zu sichern. Verdienen Sie Prämien anstatt ihr Ether in ihren Krypto-Wallets
                    verstauben zu lassen.
                  </p>
                </Row>
              </Col>

              <Col span={24}>
                <Row align='middle'>
                  <Col span={22}>
                    <h4 className='hp-font-weight-500 hp-mb-0'>Rewards verdienen ohne eigene Infrastruktur</h4>
                  </Col>
                </Row>
                <Row className='hp-pt-24'>
                  <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
                    Mit mit Winthereum Staking Service haben Sie die Möglichkeit Ihr Ether zu staken, Rewards zu
                    erhalten ohne selbst die notwendige Infrastruktur bereitstellen zu müssen.
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* <div className='hp-landing-container hp-mt-sm-0 hp-my-64'>
        <Row align='middle' justify='space-between'>
          <Col lg={6} span={24}>
            <div className='hp-position-relative hp-pb-64'>
              <img src={content2Image} alt='' />
            </div>
          </Col>

          <Col lg={18} md={16} span={24}>
            <h2 className='h3 hp-mb-32'>Enterprise Ethereum Staking Service</h2>

            <p className='h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48'>
              Winthereum bietet einen institutionellen Staking-Service für Ether-Inhaber an, der es Ihnen ermöglicht, am
              Staking von Ethereum 2 teilzunehmen, Belohnungen zu verdienen und gleichzeitig das Ethereum 2-Netzwerk zu
              sichern. Verdienen Sie Prämien anstatt ihr Ether in ihren Krypto-Wallets verstauben zu lassen.
            </p>
          </Col>
        </Row>
      </div>
      <div className='hp-landing-container hp-mt-sm-0 hp-my-64'>
        <Row align='middle' justify='space-between'>
          <Col lg={18} md={16} span={24}>
            <h2 className='h1 hp-mb-32'>Rewards verdienen ohne eigene Infrastruktur</h2>

            <p className='h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48'>
              Mit mit Winthereum Staking Service haben Sie die Möglichkeit Ihr Ether zu staken, Rewards zu erhalten ohne
              selbst die notwendige Infrastruktur bereitstellen zu müssen.
            </p>
          </Col>
          <Col lg={6} span={24}>
            <div className='hp-position-relative hp-pb-64'>
              <img src={content2Image} alt='' />
            </div>
          </Col>
        </Row>
      </div> */}
    </section>
  );
}
