import React from 'react';

import { Col, Row, Button } from 'antd';
import backgroundImage from '../../assets/images/pages/landing/banner_background.png';
import featuresImage from '../../assets/images/pages/landing/features.svg';

export default function LandingFeatures() {
  return (
    <section className='hp-landing-features'>
      <div
        className='hp-landing-features-background'
        style={{
          backgroundImage: 'url(' + backgroundImage + ')',
        }}
      >
        <Row align='middle' justify='center'>
          <Col xl={24} lg={14} md={16} span={24} className='hp-px-16 hp-text-center hp-mt-sm-64 hp-mt-96'>
            <img src={featuresImage} />

            <p className='h1 hp-mb-32' style={{ color: 'white' }}>
              Nehmen Sie Teil an der Zukunft des dezentralen Finanzwesens
            </p>
          </Col>
          <Col span={24} className='hp-px-16 hp-text-center hp-mt-sm-32 hp-pb-120'>
            <Button type='primary'>Anmelden</Button>
          </Col>
        </Row>
      </div>
    </section>
  );
}
