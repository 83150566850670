import React from 'react';
import { Avatar, Button, Col, Row } from 'antd';

import content1Image from '../../assets/images/pages/landing/banner.png';
import backgroundImage from '../../assets/images/pages/landing/banner_background.png';

export default function LandingHero() {
  return (
    <section
      className='hp-landing-banner-1 hp-border-color-dark-80 hp-overflow-hidden  hp-mt-20 hp-py-sm-24 hp-py-120'
      style={{
        backgroundImage: 'url(' + backgroundImage + ')',
      }}
    >
      <div className='hp-landing-container hp-mr-md-0 hp-mr-120'>
        <Row align='middle' justify='space-between'>
          <Col xl={10} lg={14} md={16} span={24}>
            <p className='h1 hp-mb-32 ' style={{ color: 'white' }}>
              MANAGED STAKING SERVICE{' '}
            </p>

            <p className='h3 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-48 hp-pr-96'>
              Enterprise Ethereum Staking Anbieter
            </p>
            <p className='h5 hp-font-weight-300 hp-text-color-black-80 hp-text-color-dark-30 '>
              Mit der Winthereum Staking Infrastruktur passives Einkommen verdienen und immer volle Kontrolle über seine
              Vermögenswerte behalten.
            </p>
            <Button>Mehr erfahren</Button>
          </Col>

          <Col xl={14} lg={10} span={24} className='hp-mt-md-64'>
            <div className='hp-landing-banner-1-circle'></div>

            <div className='hp-landing-banner-1-img'>
              <img src={content1Image} alt='' />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
